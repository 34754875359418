<template>
  <!-- 서브페이지 내용 -->
  <div class="con-wrap">
    <CarrotTitle title="경영기획 업무요청">
      - 경영기획팀 업무관련 문의사항을 등록하시기 바랍니다.
    </CarrotTitle>
    <div class="board">
      <div class="mt-40">
        <div>
          <table class="table-row table-serach">
            <tbody>
              <tr>
                <th width="180">업무요청일</th>
                <td>
                  <carrot-date-picker
                    v-model="bbs.sdate"
                    class="w-110px float-left"
                  ></carrot-date-picker>
                  <span class="float-left ml-10 mr-10">~</span>
                  <carrot-date-picker
                    v-model="bbs.edate"
                    class="w-110px float-left"
                  ></carrot-date-picker>
                </td>
                <th width="180">분류</th>
                <td>
                  <select v-model="bbs.ctype" class="w-50per">
                    <option value="">분류 선택</option>
                    <option v-for="v in bbs.ctype_list" :key="v" :value="v">
                      {{ v }}
                    </option>
                  </select>
                </td>
              </tr>
              <tr>
                <th width="180">요청자</th>
                <td>
                  <!--<carrot-office v-if="bbs.is_mounted" idx_office="99999" v-model="bbs.office" class="w-45per"></carrot-office>-->
                  <carrot-staff
                    v-if="bbs.is_mounted"
                    :orderby="'kasc'"
                    :idx_office="bbs.office"
                    v-model="bbs.writer"
                    class="w-45per ml-10"
                  ></carrot-staff>
                </td>
                <th width="180">담당자</th>
                <td>
                  <carrot-staff
                    v-if="bbs.is_mounted"
                    idx_office="38"
                    v-model="bbs.manager"
                    class="w-50per"
                  ></carrot-staff>
                </td>
              </tr>
              <tr>
                <th width="180">제목</th>
                <td colspan="3">
                  <input
                    type="text"
                    v-model.trim="bbs.title"
                    class="w-100per"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <button @click="bbs.doSearch" class="btn-default float-right mt-10">
            검색
          </button>
          <div class="clear"></div>

          <div class="mt-40 mb-20">
            <select v-model="bbs.order" @change="bbs.doInit" class="w-200px">
              <option value="rdesc">업무요청일 최신순</option>
              <option value="cdesc">희망완료일 최신순</option>
            </select>
            <select
              v-model="bbs.filter"
              @change="bbs.doInit"
              class="w-200px ml-20"
            >
              <option value="">요청+담당 전체</option>
              <option value="myreq">내가 요청한 내역보기</option>
              <option value="mymgr">내 담당 내역보기</option>
            </select>
            <select
              v-model="bbs.state"
              @change="bbs.doInit"
              class="w-200px ml-20"
            >
              <option value="">진행상태 전체</option>
              <option
                v-for="(irow, i) in bbs.stype_list"
                :key="i"
                :value="irow.code"
              >
                {{ irow.name }}
              </option>
            </select>
            <button @click="bbs.showAdd" class="btn-default float-right">
              업무요청
            </button>
            <div class="clear"></div>
          </div>

          <table class="table-col table-auto">
            <!-- <colgroup>
              <col width="80" />
              <col width="140" />
              <col width="100" />
              <col width="300" />
              <col width="170" />
              <col width="120" />
              <col width="170" />
              <col width="120" />
            </colgroup> -->
            <thead>
              <tr>
                <th>No</th>
                <th>업무요청일</th>
                <th>분류</th>
                <th>제목</th>
                <th>요청자</th>
                <th>희망완료일</th>
                <th>담당자</th>
                <th>진행상태</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(irow, i) in bbs.list" :key="i">
                <td>{{ irow.num }}</td>
                <td>{{ irow.request_date }}</td>
                <td>{{ irow.ctype }}</td>
                <td class="txt-left txt-ellipsis">
                  <span @click="bbs.showView(irow.idx)" class="btn-view"
                    >{{ irow.title }}
                    <span v-if="irow.cmt_cnt != 0"
                      >({{ irow.cmt_cnt }})</span
                    ></span
                  >
                </td>
                <td class="txt-ellipsis">
                  {{ irow.hq_ename }}({{ irow.hq_kname }})
                </td>
                <td>{{ irow.complete_date }}</td>
                <td class="txt-ellipsis">
                  <template v-if="irow.idx_hq > 0">
                    {{ irow.mgr_ename }}({{ irow.mgr_kname }})
                  </template>
                  <template v-else>-</template>
                </td>
                <td>
                  <span v-if="irow.state == 'REQEUST'" class="color-s1"
                    >작업요청</span
                  >
                  <span v-if="irow.state == 'PROCESS'" class="color-s2"
                    >작업진행</span
                  >
                  <span v-if="irow.state == 'READY'" class="color-s3"
                    >작업예정</span
                  >
                  <span v-if="irow.state == 'HOLD'" class="color-s4"
                    >작업보류</span
                  >
                  <span v-if="irow.state == 'DONE'" class="color-s5"
                    >작업완료</span
                  >
                  <span v-if="irow.state == 'UNABLE'" class="color-s6"
                    >처리불가</span
                  >
                  <span v-if="irow.state == 'CONFIRM'" class="color-s7"
                    >확인완료</span
                  >
                </td>
              </tr>
              <tr v-if="bbs.total == 0">
                <td colspan="9">경영기획 업무요청 정보가 없습니다.</td>
              </tr>
            </tbody>
          </table>
          <CarrotPaging
            :total="bbs.total"
            :list_per_page="bbs.rows"
            v-model="bbs.page"
            @change="bbs.doSearch"
          ></CarrotPaging>
        </div>
      </div>
    </div>
  </div>
  <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import axios from "@/plugins/axios.js";
import CarrotDatePicker from "@/components/common/CarrotDatePicker.vue";
//import CarrotOffice from '@/components/common/CarrotDept.vue'
import CarrotStaff from "@/components/common/CarrotStaff.vue";

export default {
  layout: "BusinessManagement",
  components: {
    CarrotDatePicker,
    //CarrotOffice,
    CarrotStaff,
  },
  setup() {
    const router = useRouter();
    const toast = useToast();

    const bbs = reactive({
      is_mounted: false,
      ctype_list: [],
      stype_list: [],

      page: 1,
      rows: 50,
      sdate: "",
      edate: "",
      ctype: "",
      office: 0,
      writer: 0,
      manager: 0,
      title: "",
      order: "rdesc",
      filter: "",
      state: "",

      list: [],
      total: 0,

      doSearchCtype: () => {
        let params = {};
        axios
          .get("/rest/businessmgr/managementPlanCtype", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              bbs.ctype_list = res.data.list;
            } else {
              if (res.data.err_msg) toast.error(res.data.err_msg);
            }

            sessionStorage.removeItem("ManagementPlanList");
          });
      },

      doSearchStype: () => {
        let params = {};
        axios
          .get("/rest/businessmgr/managementPlanStype", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              bbs.stype_list = res.data.list;
            } else {
              if (res.data.err_msg) toast.error(res.data.err_msg);
            }

            sessionStorage.removeItem("ManagementPlanList");
          });
      },

      doInit: () => {
        bbs.page = 1;
        bbs.doSearch();
      },

      doSearch: () => {
        if (bbs.is_mounted == false) return;

        let params = {
          page: bbs.page,
          rows: bbs.rows,
          sdate: bbs.sdate,
          edate: bbs.edate,
          ctype: bbs.ctype,
          office: bbs.office,
          writer: bbs.writer,
          manager: bbs.manager,
          title: bbs.title,
          order: bbs.order,
          filter: bbs.filter,
          state: bbs.state,
        };
        axios
          .get("/rest/businessmgr/managementPlanList", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              bbs.list = res.data.list;
              bbs.total = res.data.total;

              bbs.is_mounted = true;
            } else {
              if (res.data.err_msg) toast.error(res.data.err_msg);
            }

            sessionStorage.removeItem("ManagementPlanList");
          });
      },

      showView: (idx) => {
        sessionStorage.setItem(
          "ManagementPlanList",
          JSON.stringify({
            page: bbs.page,
            sdate: bbs.sdate,
            edate: bbs.edate,
            ctype: bbs.ctype,
            office: bbs.office,
            writer: bbs.writer,
            manager: bbs.manager,
            title: bbs.title,
            order: bbs.order,
            filter: bbs.filter,
            state: bbs.state,
          })
        );

        router.push({
          name: "BusinessManagement-ManagementPlanView-idx",
          params: { idx: idx },
        });
      },

      showAdd: () => {
        sessionStorage.setItem(
          "ManagementPlanList",
          JSON.stringify({
            page: bbs.page,
            sdate: bbs.sdate,
            edate: bbs.edate,
            ctype: bbs.ctype,
            office: bbs.office,
            writer: bbs.writer,
            manager: bbs.manager,
            title: bbs.title,
            order: bbs.order,
            filter: bbs.filter,
            state: bbs.state,
          })
        );

        router.push({
          name: "BusinessManagement-ManagementPlanAdd",
        });
      },
    });

    onMounted(() => {
      // Mounted
      bbs.doSearchCtype();
      bbs.doSearchStype();

      let ss = sessionStorage.getItem("ManagementPlanList");
      if (ss) {
        ss = JSON.parse(ss);
        bbs.page = ss.page ? ss.page : 1;
        if (ss.sdate) bbs.sdate = ss.sdate;
        if (ss.edate) bbs.edate = ss.edate;
        bbs.office = ss.office > 0 ? ss.office : 0;
        bbs.writer = ss.writer > 0 ? ss.writer : 0;
        bbs.manager = ss.manager > 0 ? ss.manager : 0;
        bbs.title = ss.title ? ss.title : "";
        bbs.order = ss.order ? ss.order : "rdesc";
        bbs.filter = ss.filter ? ss.filter : "";
        bbs.state = ss.state ? ss.state : "";
      }

      bbs.is_mounted = true;
      bbs.doSearch();
    });

    return { bbs };
  },
};
</script>

<style lang="scss" scoped>
.sub-wrap .con-wrap .table-col.table-auto {
  table-layout: auto;
}
</style>
